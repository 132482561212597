import axios from "axios";
import Kjur from "../utils/jwt";

const bearerToken = Kjur.encode(
  JSON.parse(localStorage.getItem("userDetails"))
);

let axiosConfig = {
  headers: {
    "Content-Type": "application/json; charset=UTF-8",
    "Access-Control-Allow-Origin": "*",
    "Strict-Transport-Security": "max-age=90",
    Authorization: `Bearer ${bearerToken}`,
  },
};

export const fetchRoles = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/roles`,
    axiosConfig
  );
};

export const fetchPermissions = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/permissions`,
    axiosConfig
  );
};

export const fetchUsers = async (userData) => {
  const data = {
    page: userData.page,
    limit: userData.limit,
    keyword: userData.keyword,
    role_ids: userData.roleIds,
    is_verified: userData.isVerified,
    is_bidding_user: userData.is_bidding_user ?? false,
    direct_seller: userData.directSeller,
    // is_active:true
  };
  // console.log(data);
  const encodedData = Kjur.encode(data);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/admin/users/list?token=${encodedData}`,
    axiosConfig
  );
};

export const addUser = async (user, isDealer) => {
  const data = {
    user_first_name: user.firstName,
    user_last_name: user.lastName,
    client_id: user.clientId,
    role_id: user.roleId,
    contact_number: user.contactNumber,
    password: user.password,
    password_confirmation: user.password,
    user_permissions: user.permissions,
    user_contact_emails: user.email ? [ {email: user.email, is_active: true} ] : [],
    user_contact_numbers: user?.user_contact_numbers?.map((num, i) => ({
      number: num,
      is_active: i === 0 ? true : false,
    })),
    address: user.address,
    agents: user.agents,
    users_profile_cover_url: user.cover_image,
    dealer_inventory_logo: user.dealer_inventory_logo,
    rental_company_id: user.rental_company_id,
    is_bidding_user: user.isBiddingUser ?? false,
    commercial_vehicle_ad: user.commercial_vehicle_ad
  };

  if(user.permissions) {
    var checkAccessories = user.permissions.filter((item)=> item == "accessories_listing");
    if(checkAccessories) {
      data.is_accessories_user = true;
    }
  }

  if (!isDealer) {
    delete data.client_id;
  }

  const encodedData = Kjur.encode(data);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/registration`,
    { token: encodedData },
    axiosConfig
  );
};

export const editUser = async (user) => {
  const data = {
    id: user.id,
    user_first_name: user.firstName,
    user_last_name: user.lastName,
    client_id: user.clientId ?? null,
    // user_email: user.email,
    role_id: user.roleId,
    // contact_number: user.contactNumber,
    password: user.password,
    password_confirmation: user.password,
    user_permissions: user.permissions,
    user_contact_emails: user.user_contact_emails,
    user_contact_numbers: user?.user_contact_numbers?.map((num, i) => ({
      number: num,
      is_active: i === 0 ? true : false,
    })),
    address: user.address,
    agents: user.agents,
    users_profile_cover_url: user.cover_image,
    dealer_banner_image_url: user.dealer_banner_image,
    new_car_banner_image_url: user.new_car_banner_image,
    dealer_inventory_logo: user.dealer_inventory_logo,
    rental_company_id: user.rental_company_id,
    is_bidding_user: user.isBiddingUser ?? false,
    commercial_vehicle_ad: user.commercial_vehicle_ad,
  };

  if(user.permissions) {
    var checkAccessories = user.permissions.filter((item)=> item == "accessories_listing");
    if(checkAccessories) {
      data.is_accessories_user = true;
    }
  }

  const encodedData = Kjur.encode(data);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/users/update`,
    { token: encodedData },
    axiosConfig
  );
};

export const deleteUser = async (userId) => {
  const data = {
    user_id: userId,
  };

  const encodedData = Kjur.encode(data);

  console.log("delete", encodedData);

  return await axios.delete(
    `${process.env.REACT_APP_API_BASE_URL}/admin/users/destroy?token=${encodedData}`,
    // {token:encodedData},
    axiosConfig
  );
};

export const changeUserStatus = async (userId) => {
  const data = {
    id: userId,
  };

  const encodedData = Kjur.encode(data);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/users/set-status?token=${encodedData}`,
    { token: encodedData },
    axiosConfig
  );
};

export const editProfile = async (user, emails, numbers) => {
  const data = {
    id: user.id,
    user_first_name: user.firstName,
    user_last_name: user.lastName,
    user_contact_emails: emails,
    user_contact_numbers: numbers,
  };

  const encodedData = Kjur.encode(data);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/profile`,
    { token: encodedData },
    axiosConfig
  );
};

export const verifyEmail = async (emailData) => {
  const data = {
    email: emailData,
  };

  const encodedData = Kjur.encode(data);

  return await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/email-checker?token=${encodedData}`,
    axiosConfig
  );
};

export const requestOtpForResetPassword = async (payload) => {
  const data = {
    is_email: payload.isEmail,
    creds: payload.creds,
  };

  const encodedData = Kjur.encode(data);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/reset-password`,
    { token: encodedData },
    axiosConfig
  );
};

export const newPassword = async (payload) => {
  const data = {
    _id: payload.id,
    password: payload.password,
    password_confirmation: payload.confirmPassword,
  };

  const encodedData = Kjur.encode(data);

  return await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/admin/new-password`,
    { token: encodedData },
    axiosConfig
  );
};

export const updateAccountStatus = async (id) => {
  const data = {
    id: id,
  };

  const encodedData = Kjur.encode(data);

  return await axios.put(
    `${process.env.REACT_APP_API_BASE_URL}/admin/users/set-status?token=${encodedData}`,
    null,
    axiosConfig
  );
};
