import { Tabs } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { IoMdAddCircleOutline } from "react-icons/io";
import CarDetails from "./CarDetails";
import "./carform.scss";
import moment from "moment";
import {
  fetchBrands,
  fetchCarDetails,
  fetchCities,
  fetchModels,
  fetchPackages,
} from "../../stores/api_calls/carlists";
import UploadCarImages from "./UploadCarImages";
import PreviewCar from "./PreviewCar";
import { useLocation } from "react-router-dom";
import Kjur from "../../stores/utils/jwt";
import {
  validateProductForm,
  validateUpload,
} from "../../stores/helpers/productFormValidation";
import {
  fuelTypes,
  newCarConditions,
  ownerIdTypes,
  vehicleTypes,
} from "../../contants/variables";
import { fetchUsers } from "../../stores/api_calls/users";
import { AccessContext } from "../../stores/contexts/accessContext";
import NewCarDetails from "./NewCarDetails";
import PreviewNewCar from "./PreviewNewCar";
import { convertToRaw } from "draft-js";

const { TabPane } = Tabs;

const NewCarFormIndex = (props) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [isEdit, setIsEdit] = useState(false);
  // const [roles,permissions] = useContext(AccessContext)
  const [cities, setCities] = useState({ loading: false, data: [] });
  const [brands, setBrands] = useState({ loading: false, data: [] });
  const [dealers, setDealers] = useState({ loading: false, data: [] });
  const [packages, setPackages] = useState([]);
  const [models, setModels] = useState({ loading: false, data: [] });
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [urlsHolder, setUrlsHolder] = useState([]);
  const [saleAdvisers, setSaleAdvisers] = useState([]);
  const [form, setForm] = useState({
    dealerId: ``,
    dealerMobileNumber: "",
    dealerEmail: "",
    direct_seller: false,
    displayMobile: 0,
    enableInquiry: 0,
    packageId: "",
    plateNumber: "",
    ownerIdType: "",
    ownerId: "",
    isManualInput: 0,
    registrationDate: moment(),
    engineCap: "",
    omv: "",
    arf: "",
    coe: "",
    coeExpiryDate: moment(),
    numberOfOwners: "",
    vehicleType: "",
    carBrand: "",
    carModel: "",
    askingPrice: 0,
    monthly_installment_price: 0,
    option_label_1: "",
    new_condition: "",
    manufacturedYear: "",
    cityId: "",
    cityName: "",
    regionName: "",
    address: "",
    isOffPeak: false,
    offPeakOption: 0,
    transmission: "",
    fuelType: "",
    mileage: "",
    specifications: "",
    features: "",
    accessories: "",
    description: "",
    carName: "",
    condition: "New",
    isRental: false,
    vehicleNumber: "",
    vehicleUnitNumber: "",
    vehicleReferenceNumber: "",
    vehicleRegistrationCardNumber: "",
    imageUrls: [],
    deregValue: "",
    depreciationValue: "",
    role: "",
    curbWeight: "",
    roadTax: "",
    power: "",
    dealerFirstName: "",
    dealerLastName: "",
    color: "",
    youtubeVideo: "",
    tiktokVideo: "",
    newCategory: "",
    isVerified: false,
    isSendEmail: false,
    productAgents: [],
    // longAdImage1: "",
    // selectedLongAdFile1: null,
    // longAdImage2: "",
    // selectedLongAdFile2: null,
  });
  const [enableTab, setEnableTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile1, setSelectedFile1] = useState({ file: null, url: "" });
  const [selectedFile2, setSelectedFile2] = useState({ file: null, url: "" });

  // useEffect(() => {
  //   if (form) {
  //     const value = validateProductForm(form);
  //     if (value) {
  //       setEnableTab(1);
  //     } else {
  //       setEnableTab(0);
  //     }
  //   }
  // }, [form]);

  // useEffect(() => {
  //   if ((selectedFiles || form.imageUrls) && enableTab === 1) {
  //     const value = validateUpload(selectedFiles, form.imageUrls);
  //     if (value) {
  //       setEnableTab(2);
  //     } else {
  //       setEnableTab(1);
  //     }
  //   }
  // }, [selectedFiles, form.imageUrls, enableTab]);

  useEffect(() => {
    getCities();
    getBrands();
    getPackages();
    getDealers();

    if (location.pathname.includes("/edit")) {
      setIsEdit(true);
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      getCarDetails();
    }
  }, [isEdit]);

  useEffect(() => {
    // if (form.carBrand && !isEdit) {
    //   setModels({ ...models, loading: true, data: [] });
    //   setForm({ ...form, carModel: "" });
    //   getModels();
    // } else if (form.carBrand && isEdit) {
    //   getModels();
    // }
    if(form.carBrand) {
      setModels({ ...models, loading: true, data: [] });
      setForm({ ...form, carModel: "" });
      getModels();
    } else {
      setModels({ ...models, loading: false, data: [] });
      setForm({ ...form, carModel: "" });
    }
  }, [form.carBrand]);

  useEffect(() => {
    if (!form.isOffPeak) {
      setForm({ ...form, offPeakOption: 0 });
    }
  }, [form.isOffPeak]);

  useEffect(() => {
    if (form.cityId && isLoading) {
      cities.data.forEach((city) => {
        if (city._id === form.cityId) {
          setForm({
            ...form,
            cityName: city.city,
            regionName: city.region_name,
          });
        }
      });
    }
  }, [form.cityId, isLoading]);

  useEffect(() => {
    if (cities.data.length && isEdit) {
      cities.data.forEach((city) => {
        if (city.city === form.cityName) {
          // console.log(city._id);
          setForm({ ...form, cityId: city._id });
        }
      });
    }
  }, [cities.data, isLoading]);

  // console.log(dealers)

  useEffect(() => {
    if (form.dealerId) {
      dealers.data.forEach((dealer) => {
        if (dealer._id === form.dealerId) {
          setForm({
            ...form,
            dealerMobileNumber: dealer.user_contact_numbers
              ?.filter((el) => el.is_active === true)
              .map((el) => el.number),
            dealerEmail: dealer.user_contact_emails
              ?.filter((el) => el.is_active === true)
              .map((el) => el.email),
            role: dealer.user_role,
          });
        }
      });
    }
  }, [form.dealerId]);

  const getCarDetails = async () => {
    setIsLoading(true);
    const onGetCarDetails = fetchCarDetails(params.get("id"));
    onGetCarDetails
      .then((res) => {
        if (res.data.success) {
          const parsedData = res.data.data;
          console.log("Api Data: ", parsedData);

          // const rawDraftContentState = JSON.stringify(
          //   convertToRaw(editorState.getCurrentContent())
          // );
          // // convert the raw state back to a useable ContentState object
          // const emptyContent = convertFromRaw(JSON.parse(rawDraftContentState));

          // //converting the json to display in EDITOR
          // const contentState = convertFromRaw(
          //   article.article_body ?? emptyContent
          // );
          // const newContent = EditorState.createWithContent(contentState);
          // setEditorState(newContent);
          
          setForm({
            ...form,
            id: parsedData._id,
            packageId: parsedData.selected_ads_id,
            plateNumber: parsedData.car_plate_number,
            dealerId: parsedData.advertisement_contact_details?._id ?? "",
            role:
              parsedData.advertisement_contact_details?.role_name ?? "Dealer",
            dealerEmail:
              parsedData.advertisement_contact_details?.user_contact_emails
                ?.email,
            dealerMobileNumber:
              parsedData.advertisement_contact_details?.user_contact_numbers
                ?.number,
            fullname: parsedData.advertisement_contact_details?.name,
            direct_seller: parsedData.advertisement_contact_details?.direct_seller,
            ownerIdType: parsedData.owner_type,
            ownerId: parsedData.owner_id,
            registrationDate:
              parsedData.registration_date === "N/A" ||
              !parsedData.registration_date
                ? null
                : parsedData.registration_date,
            engineCap: parsedData.product_cc,
            omv: parsedData.omv,
            arf: parsedData.arf,
            coe: parsedData.coe,
            coeExpiryDate: parsedData.coe_expiry_date,
            numberOfOwners: parsedData.number_of_owners,
            vehicleType: parsedData.vehicle_type,
            carBrand: parsedData.product_brand?._id ?? "",
            carModel: parsedData.product_brand_model?._id ?? "",
            askingPrice: parsedData.product_price,
            monthly_installment_price: parsedData.monthly_installment_price ?? 0,
            option_label_1: parsedData.option_label_1 ? parsedData.option_label_1 : null,
            new_condition: parsedData.new_condition ?? "New",
            manufacturedYear: parsedData.product_edition,
            cityName: parsedData?.product_pickup_location?.city,
            regionName: parsedData?.product_pickup_location?.region_name,
            address: parsedData?.product_pickup_location?.street,
            isOffPeak: parsedData.is_off_peak_car,
            transmission: parsedData.product_transmission,
            fuelType: parsedData.fuel_type,
            mileage: parsedData.product_mileage,
            specifications: parsedData.specifications ?? '.',
            features: parsedData.vehicle_features ?? '.',
            accessories: parsedData.accessories ?? '.',
            description: parsedData.product_description ?? '.',
            carName: parsedData.product_name,
            condition: parsedData.product_condition,
            isRental: parsedData.product_is_rental,
            vehicleNumber: parsedData.product_vehicle_number,
            vehicleUnitNumber: parsedData.product_vehicle_unit_number,
            vehicleReferenceNumber: parsedData.product_reference_number,
            vehicleRegistrationCardNumber:
              parsedData.product_vehicle_registration_card_number,
            imageUrls: parsedData.product_image_urls,
            deregValue: parsedData.dereg_value,
            depreciationValue: parsedData.depreciation_value,
            curbWeight: parsedData.curb_weight,
            roadTax: parsedData.road_tax,
            power: parsedData.power,
            color: parsedData.color,
            youtubeVideo: parsedData.youtube_video,
            tiktokVideo: parsedData.tiktok_video,
            builtIn: parsedData.built_in,
            launchYear: parsedData.launch_year,
            noOfBidding: parsedData.no_of_bidding,
            descFormatted: parsedData.description_formatted,
            featFormatted: parsedData.features_formatted,
            acccessoriesFormatted: parsedData.accessories_formatted,
            specsFormatted: parsedData.specs_formatted,
            // descriptionFormatted: parsedData.description_formatted,
            // featuresFormatted: parsedData.features_formatted,
            // acccessoriesFormatted: parsedData.accessories_formatted,
            // specsFormatted: parsedData.specs_formatted,
            brochures: parsedData.brochures,
            longAdImage1: parsedData.long_ad_image_1 ? parsedData?.long_ad_image_1 : '',
            longAdImage2: parsedData.long_ad_image_2 ? parsedData?.long_ad_image_2 : '',
            newCategory: parsedData.new_category,
            productAgents: parsedData?.product_agents ?? [],
            // isVerified: parsedData.is_verified,
            // isSendEmail: parsedData.is_send_email ?? false,
          });

          // setSaleAdvisers(parsedData?.advertisement_contact_details.agents);
          let defaultAgents = [];
          if(parsedData?.advertisement_contact_details?.agents) {
            defaultAgents = parsedData?.advertisement_contact_details?.agents?.filter(item => item && item?.fullName && item?.fullName !== "");
          }
          let sortingAgents = [];
          defaultAgents.map((item, i)=> {
            let findAgent = parsedData?.product_agents?.filter(agent => agent && agent?.fullName == item.fullName);
            sortingAgents.push({fullName: item.fullName, contactNumber : item.contactNumber, sorting: findAgent.length > 0 ? findAgent[0].sorting : i+1 })
          });
          setSaleAdvisers(sortingAgents);

          setIsLoading(false);
        }
      })
      .catch((e) => console.log(e));
  };

  const getDealers = () => {
    setDealers({ ...dealers, loading: true });
    const pagination = {
      page: 1,
      limit: 100000,
      keyword: "",
      // roleIds: roles.filter((el)=>roleNames.includes(el.name)).map((el)=>el._id),
      roleIds: ["619e5d55e7040000a2007b08", "619e5d67e7040000a2007b09"],
      isVerified: true,
    };

    const users = fetchUsers(pagination);
    users
      .then((res) => {
        if (res.data.success) {
          setDealers({ ...dealers, loading: false, data: res.data.data });
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getCities = () => {
    setCities({ ...cities, loading: true });
    const onGetCities = fetchCities();
    onGetCities
      .then((res) => {
        if (res.data.success) {
          setCities({ ...cities, loading: false, data: res.data.data });
          if (isEdit) {
            res.data.data.forEach((city) => {
              if (city.city === form.cityName) {
                setForm({ ...form, cityId: city._id });
              }
            });
          }
        }
      })
      .catch((e) => console.log(e));
  };

  const getBrands = () => {
    const pagination = {
      page: 1,
      limit: 10000,
    };
    setBrands({ ...brands, loading: true });
    const onGetBrands = fetchBrands(pagination);
    onGetBrands
      .then((res) => {
        if (res.data.success) {
          setBrands({ ...brands, loading: false, data: res.data.data });
        }
      })
      .catch((e) => console.log(e));
  };

  const getModels = () => {
    const onGetModels = fetchModels(form.carBrand);
    onGetModels
      .then((res) => {
        if (res.data.success) {
          setModels({ ...models, loading: false, data: res.data.data });
        }
      })
      .catch((e) => console.log(e));
  };

  const getPackages = () => {
    const packages = fetchPackages();
    packages
      .then((res) => {
        if (res.data.success) {
          setPackages(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="form-table-container">
      <div className={`table-title-icon ${!isEdit ? "add" : "edit"}`}>
        <IoMdAddCircleOutline />
      </div>
      <p className={`table-title-text ${!isEdit ? "add" : "edit"}`}>
        {!isEdit ? "Add New Car" : `Edit New Car`}
      </p>

      <div className="form-body-container">
        <Tabs size="large">
          <TabPane tab="Car Details Form" key="1">
            <NewCarDetails
              form={form}
              setForm={setForm}
              cities={cities}
              brands={brands}
              models={models}
              dealers={dealers}
              packages={packages}
              isEdit={isEdit}
              vehicleTypes={vehicleTypes}
              fuelTypes={fuelTypes}
              ownerIdTypes={ownerIdTypes}
              newCarConditions={newCarConditions}
              selectedFile1={selectedFile1}
              setSelectedFile1={setSelectedFile1}
              selectedFile2={selectedFile2}
              setSelectedFile2={setSelectedFile2}
              saleAdvisers={saleAdvisers}
            />
          </TabPane>
          <TabPane tab="Upload Images" key="2" disabled={!validateProductForm(form)}>
            <UploadCarImages
              isEdit={isEdit}
              form={form}
              setForm={setForm}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              urlsHolder={urlsHolder}
              setUrlsHolder={setUrlsHolder}
            />
          </TabPane>
          <TabPane tab="Car Ad Preview" key="3" disabled={!validateProductForm(form) || !validateUpload(selectedFiles, form.imageUrls)}>
            <PreviewNewCar
              isEdit={isEdit}
              form={form}
              selectedFiles={selectedFiles}

              selectedFile1={selectedFile1}
              selectedFile2={selectedFile2}
            />
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default NewCarFormIndex;
