import React , {useState , useEffect} from 'react'
import { Table , Input, Spin} from 'antd';
import './banner.scss'
import {FiSearch} from 'react-icons/fi'
import {RiEye2Line , RiDeleteBin2Fill , RiEdit2Fill} from 'react-icons/ri'
import moment from 'moment'
import {FaBuysellads} from 'react-icons/fa'
import CustomDeleteModal from '../custom_components/customDeleteModal';
import { IconButton, PrimaryButton } from '../custom_components/customButton';
import {MdAddCircle} from 'react-icons/md';
import BannerForm from '../forms/banners/BannerForm';
import { colorScheme } from '../contants/variables';
import { deleteBanner, fetchBanners } from '../stores/api_calls/bannerlists';
import { error, success } from '../contants/snackbars';
import BannerPreviewModal from '../custom_components/bannerPreviewModal';


const BannerLists = () => {

    const [tableData,setTableData] = useState([])
    const [isLoading,setIsLoading] = useState([])
    const [isEdit , setIsEdit] = useState(false)
    const [keywordSearch , setKeywordSearch] = useState("")
    const [showModal , setShowModal] = useState({showDelete:false , showForm:false , showPreview:false ,  data:{}})
    const [tablePagination , setTablePagination] = useState({
        current: 1,
        pageSize: 10,
        total:10,
        defaultPageSize: 10, 
        showSizeChanger: true, 
        pageSizeOptions: ['10', '20', '30']
    })

    const columns = [
        {
            title: 'Banner Name',
            dataIndex: 'bannerName',
            // sorter: true,
            render: (text ,record) => `${record.name}`,
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            render: (text ,record) => `${record.client_name}`,
        },
        {
            title: 'Banner Type',
            dataIndex: 'bannerType',
            render: (text ,record) => `${record.banner_type}`,
        },
        {
            title: 'Start Date',
            dataIndex: 'startDate',
            render: (text ,record) => `${moment(record.start_date).format('LL')}`,
        },
        {
            title: 'End Date',
            dataIndex: 'endDate',
            render: (text ,record) => `${moment(record.end_date).format('LL')}`,
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploadedBy',
            render: (text ,record) => `${record.created_by}`,
        },
        {
            title: 'Updated By',
            dataIndex: 'updateddBy',
            render: (text ,record) => `${record.updated_by??"Not Yet Edited"}`,
        },
        {
            title:'Actions',
            dataIndex:'actions',
            render:(text,record)=>(
                <div className='actions-icons-container'>
                    <IconButton className='icon-button view' tootTipTitle="View Banner" Icon={<RiEye2Line/>} onClick={()=>onPreview(record.banner_image_urls)}/>
                    <IconButton className='icon-button edit' tootTipTitle="Edit Banner" Icon={<RiEdit2Fill/>} onClick={()=>onOpenForm('edit' , record)}/>
                    <IconButton className='icon-button delete' tootTipTitle="Delete Banner" Icon={<RiDeleteBin2Fill/>} onClick={()=>onDelete(record)}/>
                </div>
            )
        }
    ];

    useEffect(()=>{
        refetchLists()
    },[])

    useEffect(()=>{
        if(tablePagination.current || tablePagination.pageSize){
            refetchLists()
        }
    },[tablePagination.current , tablePagination.pageSize])

    const refetchLists = () => {
        const pagination = {
            page:tablePagination.current,
            limit:tablePagination.pageSize,
            keyword:keywordSearch
        }

        fetchData(pagination)
    }

    const fetchData = (data) => {

        setIsLoading(true)

        const banners = fetchBanners(data)

        banners.then((res)=>{
            if(res.data.success){
                setTableData(res.data.data)
                setTablePagination({
                    ...tablePagination,
                    total:res.data.meta.pagination.total
                })
                setIsLoading(false)
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
    }

    const onEnter = (e) => {
        if (e.key === "Enter") {
            const pagination = {
                page : 1,
                limit : 10,
                keyword:keywordSearch
            }
            fetchData(pagination)
        }
    }

    const handleTableChange = (pagination, filters, sorter) => {
        setTablePagination(pagination)
    };

    const onDelete = (record) => {
        setShowModal({...showModal , showDelete:true , data:record})
    }

    const onDeleteSubmit = () => {
        const removeBanner = deleteBanner(showModal.data.id)
        removeBanner.then((res)=>{
            if(res.data.success){
                success("Banner Deleted Successfully")
                setShowModal({...showModal , showDelete:false , data:{}})
                refetchLists()
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
    }

    const onOpenForm = (action , record) => {
        if(action==="add"){
            setIsEdit(false)
            setShowModal({...showModal , showForm:true})
        }else{
            setIsEdit(true)
            setShowModal({...showModal , showForm:true , data:record})
        }
    }

    const onPreview = (image) => {
        setShowModal({...showModal , showPreview:true , data :{url:image.metadata.image_url}})
    }

    const onSubmitForm = () => {
        setShowModal({...showModal , showForm:false , data:{}})
    }

    return (
        <div className='banner-table-container'>
            <div className='table-title-icon primary'>
                <FaBuysellads/>
            </div>
            <p className='table-title-text primary'>Banner Ad Lists</p>

            <div
                className='search-container'
            >
                <div className='add-button-container'>
                    <PrimaryButton Icon={<MdAddCircle/>} color={colorScheme.primary} label="Create Ad Banner" onClick={()=>onOpenForm('add')}/>
                </div>

                <Input
                    style={{width:300}}
                    size='large'
                    placeholder='Search Record'
                    prefix={<FiSearch/>}
                    onChange={e=>setKeywordSearch(e.target.value)}
                    onKeyPress={onEnter}
                />
            </div>
            
            <div className='scrollable-table-container'>
                <Table
                    columns={columns}
                    rowKey={record => record._id}
                    dataSource={tableData}
                    pagination={tablePagination}
                    loading={{indicator:<Spin size='large'/> , spinning:isLoading}}
                    onChange={handleTableChange}
                    bordered
                    sticky
                />
            </div>

            <BannerForm
                isEdit={isEdit}
                visible={showModal.showForm}
                onSubmit={onSubmitForm}
                onCancel={()=>{
                    setShowModal({...showModal , showForm:false , data:{}})
                    setIsEdit(false)
                }}
                data={showModal.data}
                fetchData={fetchData}
                tablePagination={tablePagination}
            />

            <BannerPreviewModal
                visible={showModal.showPreview}
                onCancel={()=>setShowModal({...showModal , showPreview:false , data:{}})}
                imageUrl={showModal.data.url}
            />
            
            <CustomDeleteModal
                title="Delete Banner Ad"
                visible={showModal.showDelete}
                onSubmit={onDeleteSubmit}
                onCancel={()=>setShowModal({...showModal , showDelete:false , data:{}})}
                data={{itemName:`${showModal.data.banner_name}` , type:"banner ad"}}
            />

        </div>
    )
}

export default BannerLists
