import React, { useState, useEffect } from "react";
import { Input, Spin, Table } from "antd";
import { IconButton, PrimaryButton } from "../custom_components/customButton";
import { MdAddCircle } from "react-icons/md";
import { FaTags } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import "./careers.scss";
import { deleteCareer, fetchCareers } from "../stores/api_calls/careers";
import { RiEye2Line, RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";
import moment from "moment";
import CustomDeleteModal from "../custom_components/customDeleteModal";
import { colorScheme } from "../contants/variables";
import { error, success } from "../contants/snackbars";
import { useNavigate, createSearchParams } from "react-router-dom";
import CareerPreviewModal from "../custom_components/careerPreviewModal";
import Kjur from '../stores/utils/jwt';

const CareersIndex = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [keywordSearch, setKeywordSearch] = useState("");
  const [showModal, setShowModal] = useState({
    visible: false,
    data: {},
  });

  const [showPreview, setShowPreview] = useState({
    visible: false,
    data: {},
  });
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 10,
    total: "",
    defaultPageSize: 10,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total) => `Total ${total} items`,
    pageSizeOptions: ["10", "20", "30"],
  });

  useEffect(() => {
    const pagination = {
      page: 1,
      limit: 10
    };

    fetchData(pagination);
  }, []);

  useEffect(() => {
    if (tablePagination.current || tablePagination.pageSize) {
      const pagination = {
        page: tablePagination.current,
        limit: tablePagination.pageSize,
      };
  
      fetchData(pagination);
    }
  }, [tablePagination.current, tablePagination.pageSize]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTablePagination(pagination);
  };

  const fetchData = (data) => {
    setIsLoading(true);

    const careers = fetchCareers(data);
    careers
      .then((res) => {
        if (res.data.success) {
          console.log(res.data.data);
          setTableData(res.data.data);
          setIsLoading(false);
          setTablePagination({
            ...tablePagination,
            total: res.data.meta.pagination.total,
          });
        }
      })
      .catch((e) => console.log(e));
  };

  const onAdd = () => {
    navigate("../careers/create", { replace: true });
  };

  const goToForm = (mode , data) => {
    if(mode==='edit'){
        const token = Kjur.encode(data)
        navigate(
            {
                pathname:`../careers/${mode}`,
                search:`?${createSearchParams({token:token})}`,
            },
            {replace:true}
        )
    }else{
        navigate(`../careers/${mode}`, { replace: true })
    }
}

  const columns = [
    {
      title: "Date Created",
      dataIndex: "date_created",
      key: "date_created",
      render: (text, record) => (
        <span>{moment(record.date_created).format("LL")}</span>
      ),
    },
    { title: "Created By", dataIndex: "created_by", key: "created_by" },
    {
      title: "Job Title",
      dataIndex: "job_title",
      key: "job_title",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => (
        <span>{record.status ? "Active" : "Inactive"}</span>
      ),
    },

    {
      title: "Action",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <div className="actions-icons-container">
          <IconButton
            className="icon-button view"
            tootTipTitle="View Career"
            Icon={<RiEye2Line />}
            onClick={() => handleShowPreview(record)}
          />
          <IconButton
            className="icon-button edit"
            tootTipTitle="Edit Career"
            Icon={<RiEdit2Fill />}
            onClick={() => goToForm('edit', record)}
          />
          <IconButton
            className="icon-button delete"
            tootTipTitle="Delete Career"
            Icon={<RiDeleteBin2Fill />}
            onClick={() => handleDeleteCareer(record)}
          />
        </div>
      ),
    },
  ];

  const handleShowPreview = (record) => {
    setShowPreview({ ...showPreview, visible: true, data: record });
  };

  const handleDeleteCareer = (record) => {
    setShowModal({ ...showModal, visible: true, data: record });
  };

  const onDeleteSubmit = () => {
    const filteredData = tableData.filter(
      (el) => el._id !== showModal.data._id
    );

    const onDelete = deleteCareer(showModal.data._id);
    onDelete
      .then((res) => {
        if (res.data.success) {
          success("Career deleted successfully.");
          setTableData(filteredData);
          setShowModal({ ...showModal, visible: false, data: {} });
        }
      })
      .catch((e) => {
        error(`${e.response.data.server_response}`);
      });
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {
      const pagination = {
        page: 1,
        limit: 10,
        keyword: keywordSearch,
      };
      fetchData(pagination);
    }
  };

  return (
    <div className="career-table-container">
      <div className="table-title-icon primary">
        <FaTags />
      </div>
      <p className="table-title-text primary">Careers</p>
      <div className="search-container">
        <div className="add-button-container">
          <PrimaryButton
            Icon={<MdAddCircle />}
            color={colorScheme.primary}
            label="Add New Career"
            onClick={() => goToForm('create')}
          />
        </div>

        {/* <Input
          style={{ width: 300 }}
          size="large"
          placeholder="Press Enter to Search Record"
          prefix={<FiSearch />}
          value={keywordSearch}
          onChange={(e) => setKeywordSearch(e.target.value)}
          onKeyPress={onEnter}
        /> */}
      </div>

      <div className='scrollable-table-container'>
        <Table
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={tableData}
          loading={{ indicator: <Spin size="large" />, spinning: isLoading }}
          pagination={tablePagination}
          onChange={handleTableChange}
          bordered
          sticky
        />
      </div>

      <CustomDeleteModal
        title={`Delete Career`}
        visible={showModal.visible}
        onSubmit={onDeleteSubmit}
        onCancel={() => {
          setShowModal({ ...showModal, visible: false, data: {} });
        }}
        data={{ itemName: `${showModal.data.job_title}`, type: "career" }}
      />

      <CareerPreviewModal
        visible={showPreview.visible}
        onCancel={() => setShowPreview({ ...showPreview, visible: false })}
        data={showPreview.data}
      />
    </div>
  );
};

export default CareersIndex;
