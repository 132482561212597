import React , {useState , useEffect} from 'react';
import { fetchTotalAccessoryCount } from '../../stores/api_calls/dashboard';
import { GoVerified } from "react-icons/go";
import {AiOutlineArrowRight} from 'react-icons/ai'
import './card.scss'
import { useNavigate } from 'react-router-dom';

const VerifiedAccessoryCard = () => {

    const navigate = useNavigate()
    const [total , setTotal] = useState()
    const [isLoading , setIsLoading] = useState(false)

    useEffect(()=>{
        setIsLoading(true);
        const params = {
            product_status: "verified"
        }

        const apiResponse = fetchTotalAccessoryCount(params);
        apiResponse.then((res)=>{
            if(res.data){
                if(res.data.success){
                    setTotal(res?.data?.data?.total_count)
                    setIsLoading(false)
                }
            }
        }).catch((e)=>{
            console.log(e)
        })
    },[])

    return (
        <div>
            <div className='total-verified-accessory header-card'>
                <p className='header-text'>Verified Accessories</p>
                <GoVerified style={{width:"80px"}}/>
            </div>
            <div className='total-banner body-card'>
                <p className='active-text'>Total : {isLoading ? 'fetching' : !isLoading && total ? total : 0}</p>
                <div className='goIcon' onClick={()=>navigate("/car-accessories/verified", { replace: true })}>
                    <AiOutlineArrowRight/>
                </div>
            </div>
        </div>
    )
}

export default VerifiedAccessoryCard;
