import Modal from "antd/lib/modal/Modal";
import "./rentals.scss";
import React, { useState, useEffect, useRef } from "react";
import { Col, Input, Row, Select, TimePicker } from "antd";
import moment from "moment";
import { fetchCities } from "../../stores/api_calls/carlists";
import { error, success } from "../../contants/snackbars";
import { IoMdCloseCircle } from "react-icons/io";
import { addCompany, editCompany } from "../../stores/api_calls/rentals";
import { validateCompanyForm } from "../../stores/helpers/companyFormValidation";
import { FaUpload } from "react-icons/fa";
import { colorScheme } from "../../contants/variables";
import { uploadImage } from "../../stores/api_calls/upload";

const ClientForm = (props) => {
  const { isEdit, data, onCancel, visible, refetchLists, roles } = props;
  const [submitLoading, setSubmitLoading] = useState(false);
  const [locations, setLocations] = useState([]);
  const fileUploader = useRef(null);
  const [selectedFile, setSelectedFile] = useState({ file: null, url: "" });

  const [form, setForm] = useState({
    companyId: "",
    firstName: "",
    lastName: "",
    companyName: "",
    roleId: "658e617d29d536069311e793",
    // roleId: "619e5d67e7040000a2007b09",
    companyWebsite: "",
    companyStreet: "",
    companyCity: "",
    companyRegion: "",
    weekDaysOpen: "1:00",
    weekDaysClose: "23:00",
    weekEndsOpen: "1:00",
    weekEndsClose: "23:00",
    phoneNumbers: [],
    emailAdds: "",
    // emailAdds: [],
    cityId: "",
    emailValue: "",
    phoneValue: "",
    imageUrl: "",
  });

  useEffect(() => {
    const getCities = fetchCities();
    getCities
      .then((res) => {
        if (res.data.success) {
          setLocations(res.data.data);
        }
      })
      .catch((e) => {
        error(e.response.message);
      });
  }, []);

  useEffect(() => {
    if (form.cityId) {
      locations.filter((el) => {
        if (form.cityId === el._id) {
          setForm({
            ...form,
            companyCity: el.city,
            companyRegion: el.region_name,
          });
        }
      });
    }
  }, [form.cityId]);

  useEffect(() => {
    if (isEdit) {
      const selectedLocation = locations.filter(
        (el) =>
          el.region_name === data.company_location.region_name &&
          el.city === data.company_location.city
      );

      setForm({
        ...form,
        companyId: data._id,
        firstName: data.user_first_name,
        lastName: data.user_last_name,
        companyName: data.company_name,
        emailValue: data.company_email ?? data.company_contact_details.email_add,
        companyWebsite: data.company_website_url,
        companyStreet: data.company_location.street,
        weekDaysOpen: data.company_hours.weekdays.opening_time,
        weekDaysClose: data.company_hours.weekdays.closing_time,
        weekEndsOpen: data.company_hours.weekends.opening_time,
        weekEndsClose: data.company_hours.weekends.opening_time,
        phoneNumbers: data.company_contact_details.phone_number,
        emailAdds: data.company_contact_details.email_add,
        cityId: selectedLocation[0]._id,
        imageUrl: data.cover_image_url,
      });
    }
  }, [isEdit]);

  const onSubmit = () => {
    setSubmitLoading(true);
    if (isEdit) {

      roles.forEach((el) => {
        if (el.name === "Rental") {
          form.roleId = el._id;
        }
      });

      if (selectedFile.file) {
        const upload = uploadImage(selectedFile.file, 1, data._id);
        upload
          .then((res) => {
            if (res.data.success) {
              form.imageUrl = res?.data?.data?.metadata?.image_url || null;
              const updateCompany = editCompany(form);
              updateCompany
                .then((res) => {
                  if (res.data.success) {
                    setSubmitLoading(false);
                    success("Successfully updated rental company.");
                    onClose();
                    refetchLists();
                  }
                })
                .catch((e) => {
                  setSubmitLoading(false);
                  if(e.response.status == 422){
                    const validationError = Object.keys(e.response.data.server_response).reduce((result, key) => {
                        return e.response.data.server_response[key][0]
                    }, {});
                    error(validationError);
                  } else {
                      error(`Opps! Something went wrong! Please try again later`)
                  }
                });
            }
          })
          .catch((e) => {
            error(e.response.data.server_response);
          });
      } else {
        const updateCompany = editCompany(form);
        updateCompany
          .then((res) => {
            if (res.data.success) {
              setSubmitLoading(false);
              success("Successfully updated rental company.");
              onClose();
              refetchLists();
            }
          })
          .catch((e) => {
            setSubmitLoading(false);
            if(e.response.status == 422){
              const validationError = Object.keys(e.response.data.server_response).reduce((result, key) => {
                  return e.response.data.server_response[key][0]
              }, {});
              error(validationError);
            } else {
                error(`Opps! Something went wrong! Please try again later`)
            }
          });
      }
    } else {
      if(selectedFile.file) {
        const upload = uploadImage(selectedFile.file);
        upload
          .then((res) => {
            if (res.data.success) {
              form.imageUrl = res?.data?.data?.metadata?.image_url || null;
  
              roles.forEach((el) => {
                if (el.name === "Rental") {
                  form.roleId = el._id;
                }
              });
  
              const createCompany = addCompany(form);
              createCompany
                .then((res) => {
                  if (res.data.success) {
                    setSubmitLoading(false);
                    success("Successfully added new rental company.");
                    onClose();
                    refetchLists();
                  }
                })
                .catch((e) => {
                  setSubmitLoading(false);
                  if(e.response.status == 422){
                    const validationError = Object.keys(e.response.data.server_response).reduce((result, key) => {
                        return e.response.data.server_response[key][0]
                    }, {});
                    error(validationError);
                  } else {
                      error(`Opps! Something went wrong! Please try again later`)
                  }
                });
            }
          })
          .catch((e) => {
            error(e.response.data.server_response);
          });
      }else {
        roles.forEach((el) => {
          if (el.name === "Rental") {
            form.roleId = el._id;
          }
        });

        const createCompany = addCompany(form);
        createCompany
          .then((res) => {
            if (res.data.success) {
              setSubmitLoading(false);
              success("Successfully added new rental company.");
              onClose();
              refetchLists();
            }
          })
          .catch((e) => {
            setSubmitLoading(false);
            if(e.response.status == 422){
              const validationError = Object.keys(e.response.data.server_response).reduce((result, key) => {
                  return e.response.data.server_response[key][0]
              }, {});
              error(validationError);
            } else {
                error(`Opps! Something went wrong! Please try again later`)
            }
          });
      }
    }
  };

  const onClose = () => {
    setForm({
      ...form,
      companyId: "",
      firstName: "",
      lastName: "",
      companyName: "",
      companyWebsite: "",
      companyStreet: "",
      companyCity: "",
      companyRegion: "",
      weekDaysOpen: "1:00",
      weekDaysClose: "23:00",
      weekEndsOpen: "1:00",
      weekEndsClose: "23:00",
      phoneNumbers: [],
      emailAdds: "",
      // emailAdds: [],z
      cityId: "",
      emailValue: "",
      phoneValue: "",
    });
    setSubmitLoading(false);
    onCancel();
  };

  const handleFieldChange = (name, e) => {
    setForm({ ...form, [name]: e.target.value });
  };

  const onChangePhoneNumber = (e) => {
    const phone = e.target.value.replace(/\D/g, "");
    if(phone.length < 9) {
      setForm({ ...form, phoneValue: phone });
    }
  }

  const onWeekdaysTimeChange = (time, timeString) => {
    setForm({
      ...form,
      weekDaysOpen: timeString[0],
      weekDaysClose: timeString[1],
    });
  };
  const onWeekendsTimeChange = (time, timeString) => {
    setForm({
      ...form,
      weekEndsOpen: timeString[0],
      weekEndsClose: timeString[1],
    });
  };

  const handleDropdownChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const handleEnter = (name, e) => {
    if (e.key === "Enter") {
      if(isValidSingaporePhoneNumber(e.target.value)) {
        setForm({
          ...form,
          [name]: [...form[name], e.target.value],
          phoneValue: "",
          // emailValue: "",
        });
      }
    }
  };

  const isValidSingaporePhoneNumber = (phoneNumber) => {
    const regex = /^[689]\d{7}$/;
    return regex.test(phoneNumber);
  }

  const deleteContact = (name, value) => {
    const filteredData = form[name].filter((el) => el !== value);
    setForm({ ...form, [name]: filteredData });
  };

  const handleOpenGallery = () => {
    fileUploader.current.click();
  };

  const handleImageChange = (e) => {
    setSelectedFile({ ...selectedFile, file: null, url: "" });
    if (e.target.files) {
      const file = e.target.files[0];
      const url = URL.createObjectURL(file);
      setSelectedFile({ ...selectedFile, file: file, url: url });
      setForm({ ...form, imageUrl: url });
    }
  };

  return (
    <div className="rental-client-form">
      <Modal
        width={950}
        className="formModal"
        title={`${isEdit ? "Edit" : "Create"} Rental Company`}
        visible={visible}
        onOk={onSubmit}
        okButtonProps={{ disabled: !validateCompanyForm(form) }}
        onCancel={onClose}
        okText={`${isEdit ? "Save" : "Submit"}`}
        confirmLoading={submitLoading}
      >
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <p className="headers">Company Details</p>
            <div className="form-container">
              <p className="form-label">
                First Name <span>*</span> :{" "}
              </p>
              <Input
                value={form.firstName}
                onChange={(text) => handleFieldChange("firstName", text)}
                placeholder="Please type First Name"
              />
            </div>
            <div className="form-container">
              <p className="form-label">
                Last Name <span>*</span> :{" "}
              </p>
              <Input
                value={form.lastName}
                onChange={(text) => handleFieldChange("lastName", text)}
                placeholder="Please type Last Name"
              />
            </div>
            <div className="form-container">
              <p className="form-label">
                Company Name <span>*</span> :{" "}
              </p>
              <Input
                value={form.companyName}
                onChange={(text) => handleFieldChange("companyName", text)}
                placeholder="Please type Company Name"
              />
            </div>

            <div className="form-container">
              <p className="form-label">Website URL : </p>
              <Input
                value={form.companyWebsite}
                onChange={(text) => handleFieldChange("companyWebsite", text)}
                placeholder="Please type Company Website"
              />
            </div>

            <div className="form-container">
              <p className="form-label">
                Address Street <span>*</span> :{" "}
              </p>
              <Input
                value={form.companyStreet}
                onChange={(text) => handleFieldChange("companyStreet", text)}
                placeholder="Please type Address Street"
              />
            </div>

            <div className="form-container">
              <p className="form-label">
                Location <span>*</span> :{" "}
              </p>
              <Select
                showSearch
                value={form.cityId || undefined}
                onChange={(value) => handleDropdownChange("cityId", value)}
                style={{ width: "100%" }}
                placeholder="Search to Select"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                filterSort={(optionA, optionB) =>
                  optionA.children
                    .toLowerCase()
                    .localeCompare(optionB.children.toLowerCase())
                }
              >
                {locations.map((el, i) => (
                  <Select.Option key={i} value={el._id}>
                    {`${el.city}, ${el.region_name}`}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div
              className="form-container"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p className="form-label">
                Weekdays Office Hours <span>*</span> :{" "}
              </p>
              <TimePicker.RangePicker
                style={{ width: "200px" }}
                showTime={{ format: "HH:mm" }}
                value={[
                  moment(form.weekDaysOpen, "HH:mm"),
                  moment(form.weekDaysClose, "HH:mm"),
                ]}
                onChange={onWeekdaysTimeChange}
              />
            </div>
            <div
              className="form-container"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <p className="form-label">
                Weekends Office Hours <span>*</span> :{" "}
              </p>
              <TimePicker.RangePicker
                style={{ width: "200px" }}
                showTime={{ format: "HH:mm" }}
                value={[
                  moment(form.weekEndsOpen, "HH:mm"),
                  moment(form.weekEndsClose, "HH:mm"),
                ]}
                onChange={onWeekendsTimeChange}
              />
            </div>
          </Col>

          <Col span={12}>
            <p className="headers">Contact Details</p>
            <div className="form-container contact-number">
              <p className="form-label">
                Phone Numbers <span>*</span> :{" "}
              </p>
              <Input
                placeholder='Press "ENTER" after input each contact number'
                value={form.phoneValue}
                onChange={(value) => onChangePhoneNumber(value)}
                onKeyDown={(text) => handleEnter("phoneNumbers", text)}
                maxLength={8}
              />
            </div>
            <div className="form-container description">
              <p className="form-label"></p>
              <p style={{width: '100%'}}>
                <small>Please insert phone number only start with <b>6</b> or <b>8</b> or <b>9</b> and must be <b> eight </b> digits.</small>
              </p>
            </div>

            <div className="contacts-chip-container">
              {form.phoneNumbers?.map((number, i) => (
                <div key={i} className="contact-container phone">
                  <IoMdCloseCircle
                    onClick={() => deleteContact("phoneNumbers", number)}
                  />
                  <p>{number}</p>
                </div>
              ))}
            </div>

            <div className="form-container">
              <p className="form-label">
                Email Address <span>*</span> :{" "}
              </p>
              <Input
                placeholder='Please type Valid Email Address'
                value={form.emailValue}
                onChange={(text) =>
                  handleFieldChange("emailValue", text)
                }
                
              />
            </div> 

            {/* <div className="contacts-chip-container">
              {form.emailAdds?.map((email, i) => (
                <div key={i} className="contact-container email">
                  <IoMdCloseCircle
                    onClick={() => deleteContact("emailAdds", email)}
                  />
                  <p>{email}</p>
                </div>
              ))}
            </div> */}

            <div style={{ marginTop: 24 }} />

            <div>
              <p style={{ margin: "5px 0x", color: colorScheme.red }}>
                {`To avoid stretching of image in the website. The image has to be (.jpg , .png) with 1200 pixels width`}
              </p>
            </div>

            <div className="form-container">
              <p className="form-label">
                Banner Image :{" "}
              </p>
              <div className="upload-container" onClick={handleOpenGallery}>
                <FaUpload />
                <p className="banner-p">UPLOAD BANNER PHOTO</p>
              </div>

              <input
                ref={fileUploader}
                id="getFile"
                type="file"
                hidden
                onChange={handleImageChange}
              />
            </div>

            <div className="image-preview-container">
              <img src={form.imageUrl} />
            </div>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default ClientForm;
