import axios from 'axios'
import Kjur from '../utils/jwt';
import moment from 'moment';

const bearerToken = Kjur.encode(JSON.parse(localStorage.getItem('userDetails')))

let axiosConfig = {
    headers: {
        "Content-Type": 'application/json; charset=UTF-8',
        "Access-Control-Allow-Origin": "*",
        "Strict-Transport-Security": 'max-age=90',
        "Authorization":`Bearer ${bearerToken}`
    }
};

export const fetchInclusions = async (section) => {

    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/inclusions`,
        axiosConfig
    )
}

export const addInclusion = async (inclusion) => {
    const data = {
        code : inclusion.code,
        name: inclusion.name
    }

    const encodedData = Kjur.encode(data)

    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/inclusions`,
        {token:encodedData},
        axiosConfig
    )
}

export const editInclusion = async (inclusion) => {
    const data = {
        id : inclusion.id,
        code : inclusion.code,
        name: inclusion.name
    }

    const encodedData = Kjur.encode(data)

    return await axios.put(`${process.env.REACT_APP_API_BASE_URL}/admin/inclusions/update`,
        {token:encodedData},
        axiosConfig
    )
}

export const deleteInclusion = async (id) => {
    const data = {
        id:id
    }

    const encodedData = Kjur.encode(data)

    return await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/admin/inclusions/delete?token=${encodedData}`,
        axiosConfig
    )
}

export const fetchPlans = async (article) => {

    const data = {
      sort_by: "date_created",
      sort_order: "desc",
      page: 1,
      limit: 20,
    };

    const encodedData = Kjur.encode(data)

    return await axios.get(`${process.env.REACT_APP_API_BASE_URL}/admin/packages?token=${encodedData}`,
        axiosConfig
    )
}


export const addPlan = async (payload) => {

    const data = {
        package_name: payload.packageName,
        package_currency: "SGD",
        package_cost: +payload.packageCost,
        number_of_photo: payload.numberOfPhoto,
        during_of_ad: payload.duringOfAd,
        package_details: payload.packageDescription,
        package_inclusions: payload.packageInclusions,
        package_user_type: payload.packageUserType
    }

    const encodedData = Kjur.encode(data)

    return await axios.post(`${process.env.REACT_APP_API_BASE_URL}/admin/packages`, 
        {token:encodedData},
        axiosConfig 
    )
}

export const editPlan = async (payload) => {

    const data = {
      id: payload.packageId,
      package_name: payload.packageName,
      package_currency: "SGD",
      package_cost: +payload.packageCost,
      number_of_photo: payload.numberOfPhoto,
      during_of_ad: payload.duringOfAd,
      package_details: payload.packageDescription,
      package_inclusions: payload.packageInclusions,
      package_user_type: payload.packageUserType,
    };

    const encodedData = Kjur.encode(data)

    return await axios.put(`${process.env.REACT_APP_API_BASE_URL}/admin/packages/update`, 
        {token:encodedData},
        axiosConfig 
    )
}

export const deletePlan = async (id) => {
    const data = {
        id : id
    }

    const encodedData = Kjur.encode(data)

    // delete method doesnt do request boyd, it should be params like get
    return await axios.delete(`${process.env.REACT_APP_API_BASE_URL}/admin/packages/delete?token=${encodedData}`, 
        // {token:encodedData},
        axiosConfig 
    )
}