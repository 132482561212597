import React, { useState, useEffect } from "react";
import { Spin, Table } from "antd";
import { IconButton, PrimaryButton } from "../custom_components/customButton";
import { MdAddCircle } from "react-icons/md";
import "./styles.scss";
import { RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";
import moment from "moment";
import CustomDeleteModal from "../custom_components/customDeleteModal";
import { colorScheme } from "../contants/variables";
import { AiFillBank } from "react-icons/ai";
import {
  deleteInsurance,
  fetchInsurances,
} from "../stores/api_calls/insurances";
import InsuranceForm from "../forms/insurances/insuranceForm";
import { error, success } from "../contants/snackbars";

const InsuranceIndex = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showForm, setShowForm] = useState(false);

  const [tableData, setTableData] = useState([]);
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 10,
    total: "",
    defaultPageSize: 10,
    showSizeChanger: true,
    showQuickJumper: true,
    showTotal: (total) => `Total ${total} items`,
    pageSizeOptions: ["10", "20", "30"],
  });
  const [showDeleteModal, setShowDeleteModal] = useState({
    visible: false,
    data: {},
  });

  useEffect(() => {
    const pagination = {
      page: 1,
      limit: 10,
    };

    fetchData(pagination);
  }, []);

  useEffect(() => {
    if (tablePagination.current || tablePagination.pageSize) {
      const pagination = {
        page: tablePagination.current,
        limit: tablePagination.pageSize,
      };
  
      fetchData(pagination);
    }
  }, [tablePagination.current, tablePagination.pageSize]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTablePagination(pagination);
  };

  const fetchData = (data) => {
    setIsLoading(true);

    const insuranceCompanies = fetchInsurances(data);
    insuranceCompanies
      .then((res) => {
        if (res.data.success) {
          setTableData(res.data.data);
          setIsLoading(false);
          setTablePagination({
            ...tablePagination,
            total: res.data.meta.pagination.total,
          });
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  const onDelete = (record) => {
    setShowDeleteModal({ ...showDeleteModal, visible: true, data: record });
  };

  const onDeleteSubmit = () => {
    const removeInsurance = deleteInsurance(showDeleteModal.data.id);
    removeInsurance
      .then((res) => {
        if (res.data.success) {
          success("Insurance Company Deleted Successfully");
          setShowDeleteModal({ ...showDeleteModal, visible: false, data: {}, isEditing: false });
          const pagination = {
            page: 1,
            limit: 10,
          };
          fetchData(pagination);
        }
      })
      .catch((e) => {
        error(`Opps! Something went wrong. Please try again later`);
      });
  };

  const onEdit = (record) => {
    setShowForm({ isEditing: true, visible: true, data: record });
  };

  const columns = [
    {
      title: "Date Created",
      dataIndex: "date_created",
      key: "date_created",
      render: (text, record) => (
        <span>{moment(record.date_created).format("LL")}</span>
      ),
    },
    {
      title: "Insurace Company",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Emails",
      dataIndex: "emails",
      key: "emails",
      width: 240,
      render: (text, record) =>
        record?.emails?.map((email) => <span key={email}>{email}, </span>),
    },
    {
      title: "Action",
      dataIndex: "actions",
      key: "actions",
      render: (text, record) => (
        <div className="actions-icons-container">
          <IconButton
            className="icon-button edit"
            tootTipTitle="Edit Insurance Company"
            Icon={<RiEdit2Fill />}
            onClick={() => onEdit(record)}
          />
          <IconButton
            className="icon-button delete"
            tootTipTitle="Delete Insurance Company"
            Icon={<RiDeleteBin2Fill />}
            onClick={() => onDelete(record)}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="career-table-container">
      <div className="table-title-icon primary">
        <AiFillBank />
      </div>
      <p className="table-title-text primary">Manage Insurance Company</p>
      <div className="search-container">
        <div className="add-button-container">
          <PrimaryButton
            Icon={<MdAddCircle />}
            color={colorScheme.primary}
            label="Add Insurance Company"
            onClick={() => setShowForm({ ...showForm, visible: true })}
          />
        </div>
      </div>

      <div className='scrollable-table-container'>
        <Table
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={tableData}
          loading={{ indicator: <Spin size="large" />, spinning: isLoading }}
          pagination={tablePagination}
          onChange={handleTableChange}
          bordered
          sticky
        />
      </div>

      <InsuranceForm
        visible={showForm.visible}
        onCancel={() => setShowForm({ ...showForm, visible: false, isEditing: false })}
        isEditing={showForm.isEditing}
        data={showForm.data}
        fetchData={fetchData}
      />

      <CustomDeleteModal
        title={`Delete Insurance Company`}
        visible={showDeleteModal.visible}
        onSubmit={onDeleteSubmit}
        onCancel={() => {
          setShowDeleteModal({ ...showDeleteModal, visible: false, data: {} });
        }}
        data={{ itemName: `${showDeleteModal.data.name}` }}
      />
    </div>
  );
};

export default InsuranceIndex;
