import { Checkbox, Input, Modal, Select } from "antd";
import React, { useState, useEffect } from "react";
import { error, success } from "../../contants/snackbars";
import { addPlan, editPlan } from "../../stores/api_calls/packages";
import { generateInclusionLabel } from "../../stores/helpers/packageFormHelpers";
import "./package.scss";

const PackageForm = (props) => {
  const { isEdit, visible, inclusions, refetchPackage, onCancel, data } = props;
  const [form, setForm] = useState({
    packageId: "",
    packageName: "",
    packageCost: "",
    numberOfPhoto: "",
    duringOfAd: "",
    packageDescription: "",
    packageInclusions: [],
    packageUsertype: null,
  });
  // console.log(isEdit, data);
  useEffect(() => {
    if (isEdit) {
      setForm({
        ...form,
        packageId: data.id,
        packageName: data.package_name,
        packageCost: data.package_cost,
        numberOfPhoto: data.number_of_photo,
        duringOfAd: data.during_of_ad,
        packageDescription: data.package_details,
        packageInclusions: data.package_inclusions,
        packageUsertype: data.package_user_type,
      });
    }
  }, [isEdit]);

  const onClose = () => {
    setForm({
      ...form,
      packageName: "",
      packageId: "",
      packageCost: "",
      numberOfPhoto: "",
      duringOfAd: "",
      packageInclusions: [],
      packageUsertype: null,
    });
    onCancel();
  };

  const handleFieldChange = (name, e) => {
    setForm({ ...form, [name]: e.target.value });
  };

  const handleCheckboxChange = (item, e) => {
    if (e.target.checked) {
      setForm({
        ...form,
        packageInclusions: [...form.packageInclusions, item.code],
      });
    } else {
      const filteredData = form.packageInclusions.filter(
        (el) => el !== item.code
      );
      setForm({ ...form, packageInclusions: filteredData });
    }
  };

  const onSubmit = () => {
    if (isEdit) {
      const updatePackage = editPlan(form);
      updatePackage
        .then((res) => {
          if (res.data.success) {
            success("Package Updated Successfully");
            refetchPackage();
          }
        })
        .catch((e) => {
          error(
            `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
          );
        });
    } else {
      const createPackage = addPlan(form);
      createPackage
        .then((res) => {
          if (res.data.success) {
            success("Package Added Successfully");
            refetchPackage();
          }
        })
        .catch((e) => {
          error(
            `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
          );
        });
    }
    onClose();
  };

  const handleDropdownChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  return (
    <div>
      <Modal
        className="formModal"
        title={`${isEdit ? "Edit" : "Create"} Package`}
        visible={visible}
        onOk={onSubmit}
        onCancel={onCancel}
        okText={`${isEdit ? "Save" : "Submit"}`}
      >
        <div className="field-container">
          <p className="field-label">Package Name : </p>
          <Input
            value={form.packageName}
            placeholder="Type Package Name"
            onChange={(text) => handleFieldChange("packageName", text)}
          />
        </div>
        {/* <div className="field-container">
          <p className="field-label">Package Cost : </p>
          <Input
            value={form.packageCost}
            placeholder="10,000"
            onChange={(text) => handleFieldChange("packageCost", text)}
            addonAfter="SGD"
            type="number"
          />
        </div> */}
        <div className="field-container">
          <p className="field-label">Package Description : </p>
          <Input
            value={form.packageDescription}
            // placeholder='Sold until when'
            onChange={(text) => handleFieldChange("packageDescription", text)}
          />
        </div>

        <div className="field-container">
          <p className="field-label">
            User type <span>*</span> :{" "}
          </p>
                  <Select
                      style={{width: "100%"}}
            value={form.articleSection || undefined}
            onChange={(value) =>
              handleDropdownChange("package_user_type", value)
            }
            placeholder="Select User Type"
            optionFilterProp="children"
          >
            {[
              { value: "dealer", name: "Dealer" },
              { value: "direct_seller", name: "Direct Seller" },
            ].map((el, i) => (
              <Select.Option key={i} value={el._id}>
                {`${el.name}`}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className="inclusions-container">
          <p style={{fontWeight:'bold'}}>Inclusions : </p>

          <div className="field-container">
            <p className="field-label">Price : </p>
            <Input
              value={form.packageCost}
              placeholder="0"
              onChange={(text) => handleFieldChange("packageCost", text)}
              addonAfter="SGD"
              type="number"
            />
          </div>
          
          <div className="field-container">
            <p className="field-label">Number of photos : </p>
            <Input
              value={form.numberOfPhoto}
              placeholder="0"
              onChange={(text) => handleFieldChange("numberOfPhoto", text)}
              type="number"
            />
          </div>
          
          <div className="field-container">
            <p className="field-label">During of Ad <small>(Weeks)</small>: </p>
            <Input
              value={form.duringOfAd}
              placeholder="0"
              onChange={(text) => handleFieldChange("duringOfAd", text)}
              type="number"
            />
          </div>

          {inclusions.map((inclusion, idx) => (
            <div className="inclusion" key={idx}>
              <Checkbox
                valuePropName="checked"
                // value={item[0]}
                checked={form.packageInclusions.includes(inclusion.code)}
                onChange={(e) => handleCheckboxChange(inclusion, e)}
              >
                {inclusion.name}
              </Checkbox>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default PackageForm;
