export const colorScheme = {
  primary: "#254A7C",
  secondary: "#20A8F4",
  tertiary: "#337AB7",
  red: "#EA3636",
  yellow: "#E3D337",
  green: "#2C9C22",
  gray: "#808080",
  lightgray: "#C2BEBE",
  black: "#4F4A4A",
  white: "#fff",
  offwhite: "#f2f2f2",
};

export const vehicleTypes = [
  "2 Door Coupe",
  "Crossover",
  "Mid-sized Sedan",
  "Luxury Sedan",
  "Hatchback",
  "MPV",
  "SUV",
  "Sports Car",
  "Stationwagon",
  "Diesel Car",
  "Hybrid Car",
  "Electric Vehicle",
  "Electric Commercial",
  "Truck",
  "Bus/Mini Bus",
  "Van",
  "Vintage Car",
  "Lorry",
  "Others",
  "Passenger Car",
];

export const usedCarCategories = [
  { value: "bid_car", label: "Bid Car" },
  { value: "coe_car", label: "COE Car" },
  { value: "opc_car", label: "OPC Car" },
];

export const fuelTypes = [
  "Diesel",
  "Diesel-Electric",
  "Petrol",
  "Petrol-Electric",
  "Electric",
];

export const rentalDrivingExperience = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "Above 5",
];

export const rentalMinMonth = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
];

export const bannerStatusTypes = [
  { value: null, label: "All status" },
  { value: true, label: "Active" },
  { value: false, label: "Expired" },
];

export const homeBannerTypes = [
  { value: "Mobile-Ad", label: "Mobile Top Header Banner" },
  { value: "Popout-Banner", label: "Popout Banner (Mobile)" },
  { value: "Popout-Rectangle", label: "Popout Banner (Website)" },
  { value: "Popout-Long", label: "Bottom Popout Banner (Website)" },
  { value: "Plate-Numbers-Fade-Slide-Ad", label: "Fade Slide Ad - Plate Numbers",},
  { value: "Car-Rental-Fade-Slide-Ad", label: "Fade Slide Ad - Car Rental",},
  { value: "Commercial-Vehicle-Fade-Slide-Ad", label: "Fade Slide Ad - Commercial Vehicle",},
  { value: "Hero-Banner", label: "Hero Banner - Landing Page" },
  { value: "Strip-Banner", label: "Strip Banner" },
  { value: "Home-Strip-Banner-1", label: "Home Strip Banner 1" },
  { value: "Home-Strip-Banner-2", label: "Home Strip Banner 2" },
  { value: "Home-Strip-Banner-3", label: "Home Strip Banner 3" },
  { value: "Home-Strip-Banner-4", label: "Home Strip Banner 4" },
  { value: "Home-Strip-Banner-5", label: "Home Strip Banner 5" },
  { value: "Home-Strip-Banner-6", label: "Home Strip Banner 6" },
  { value: "Strip-Banner-I-Max", label: "Strip Banner I-Max" },
  // { value: "Quick-Browse-I-Max", label: "Quick Browse I-Max" },
];

export const productBannerTypes = [
  { value: "Desktop-Product-Banner", label: "Desktop Product Banner" },
  { value: "Mobile-Ad", label: "Mobile Top Header Banner" },
  { value: "I-Max", label: "I-Max" },
  { value: "Side-Banner", label: "Side Banner (Square)" },
  { value: "Strip-Banner", label: "Strip Banner" },
  { value: "Product-View-Side-Banner-Ad", label: "Side Banner Ad - Car Details" },
  { value: "Product-View-Center-Ad", label: "Center Ad - Product View"  },
  { value: "Product-View-Strip-Banner-1", label: "Strip Banner - Product View" },
  { value: "Rental-View-Side-Banner-Ad", label: "Side Banner Ad - Car Rental Details" },
  { value: "Rental-Side-Banner-Ad", label: "Side Banner Ad - Car Rentals" },
  { value: "products-new-side-banner-ad", label: "Side Banner Ad - New Cars" },
  { value: "products-used-side-banner-ad", label: "Side Banner Ad - Used Cars" },
  { value: "products-bidding-side-banner-ad", label: "Side Banner Ad - Bidding Cars" },
  { value: "products-dealer-side-banner-ad", label: "Side Banner Ad - Dealer" },
  { value: "products-commercial-side-banner-ad", label: "Side Banner Ad - Commercial" },
  { value: "Vertical-Side-Banner", label: "Vertical Side Banner" },
  { value: "Strip-Banner-I-Max", label: "Strip Banner I-Max" },
  { value: "rental-strip-banner-ad", label: "Strip Banner Ad - Rental" },
];

export const otherBannerTypes = [
  { value: "I-Max", label: "I-Max" },
  { value: "Strip-Banner-I-Max", label: "Strip Banner I-Max" },
  { value: "Strip-Banner", label: "Strip Banner" },
  { value: "Free-Valuation-Strip-Banner-1", label: "Strip Banner - Free Valuation" },
  { value: "Article-Details-Side-Banner-Ad", label: "Side Banner Ad - Article Details" },
  { value: "News-Side-Banner-Ad", label: "Side Banner Ad - News" },
  { value: "Career-View-Side-Banner-Ad", label: "Side Banner Ad - Career Details" },
  { value: "Carrers-Side-Banner-Ad", label: "Side Banner Ad - Careers" },
  { value: "Plate-Numbers-Strip-Banner", label: "Strip Banner - Plate Numbers" },
  { value: "Plate-Numbers-Side-Banner-Ad", label: "Side Banner Ad - Plate Numbers" },
  { value: "Sell-Plate-Number-Side-Banner-Ad", label: "Side Banner Ad - Sell Plate Number" },
  { value: "Plate-Numbers-Fade-Slide-Ad", label: "Fade Slide Ad - Plate Numbers" },
  { value: "loan-calculator-side-banner-ad", label: "Side Banner Ad - Loan Calculator" },
  { value: "insurance-side-banner-ad", label: "Side Banner Ad - Insurance" },
  { value: "car-loan-side-banner-ad", label: "Side Banner Ad - Car Loan" },
  { value: "Vertical-Banner", abel: "Side Banner (Vertical - Login & Register Page) " },
  { value: "products-bank-ad", label: "Strip Banner Ad - Calculator" },
  { value: "car-workshop-side-banner-ad", label: "Side Banner Ad - Workshop" },
  { value: "valuation-Rectangle-strip-banner-ad", label: "Rectangle Strip Banner Ad - Valuation" },
  { value: "Vertical-Side-Banner", label: "Vertical Side Banner" },
//   { "value": "mobile-top-header-banner-ad", "label": "Mobile Top Header Banner Ad" },
];

export const bannerTypes = [
  { value: null, label: "All Banner Types" },
  { "value": "Popout-Long", "label": "Bottom Popout Banner (Website)" },
  { "value": "Product-View-Center-Ad", "label": "Center Ad - Product View" },
  { "value": "Desktop-Product-Banner", "label": "Desktop Product Banner" },
  { "value": "Car-Rental-Fade-Slide-Ad", "label": "Fade Slide Ad - Car Rental" },
  { "value": "Commercial-Vehicle-Fade-Slide-Ad", "label": "Fade Slide Ad - Commercial Vehicle" },
  { "value": "Plate-Numbers-Fade-Slide-Ad", "label": "Fade Slide Ad - Plate Numbers" },
  { "value": "Hero-Banner", "label": "Hero Banner - Landing Page" },
  { "value": "Home-Strip-Banner-1", "label": "Home Strip Banner 1" },
  { "value": "Home-Strip-Banner-2", "label": "Home Strip Banner 2" },
  { "value": "Home-Strip-Banner-3", "label": "Home Strip Banner 3" },
  { "value": "Home-Strip-Banner-4", "label": "Home Strip Banner 4" },
  { "value": "Home-Strip-Banner-5", "label": "Home Strip Banner 5" },
  { "value": "Home-Strip-Banner-6", "label": "Home Strip Banner 6" },
  { "value": "I-Max", "label": "I-Max" },
  { "value": "Mobile-Ad", "label": "Mobile Top Header Banner" },
  { "value": "Popout-Banner", "label": "Popout Banner (Mobile)" },
  { "value": "Popout-Rectangle", "label": "Popout Banner (Website)" },
  // { "value": "Vertical-Banner", "label": "Side Banner (Vertical - Login & Register Page) " },
  { "value": "valuation-Rectangle-strip-banner-ad", "label": "Rectangle Strip Banner Ad - Valuation" },
  { "value": "Side-Banner", "label": "Side Banner (Square)" },
  { "value": "Article-Details-Side-Banner-Ad", "label": "Side Banner Ad - Article Details" },
  { "value": "products-bidding-side-banner-ad", "label": "Side Banner Ad - Bidding Cars" },
  { "value": "Product-View-Side-Banner-Ad", "label": "Side Banner Ad - Car Details" },
  { "value": "car-loan-side-banner-ad", "label": "Side Banner Ad - Car Loan" },
  { "value": "Rental-View-Side-Banner-Ad", "label": "Side Banner Ad - Car Rental Details" },
  { "value": "Rental-Side-Banner-Ad", "label": "Side Banner Ad - Car Rentals" },
  { "value": "Career-View-Side-Banner-Ad", "label": "Side Banner Ad - Career Details" },
  { "value": "Carrers-Side-Banner-Ad", "label": "Side Banner Ad - Careers" },
  { "value": "products-commercial-side-banner-ad", "label": "Side Banner Ad - Commercial" },
  { "value": "insurance-side-banner-ad", "label": "Side Banner Ad - Insurance" },
  { "value": "loan-calculator-side-banner-ad", "label": "Side Banner Ad - Loan Calculator" },
  { "value": "products-new-side-banner-ad", "label": "Side Banner Ad - New Cars" },
  { "value": "News-Side-Banner-Ad", "label": "Side Banner Ad - News" },
  { "value": "Plate-Numbers-Side-Banner-Ad", "label": "Side Banner Ad - Plate Numbers" },
  { "value": "Sell-Plate-Number-Side-Banner-Ad", "label": "Side Banner Ad - Sell Plate Number" },
  { "value": "products-used-side-banner-ad", "label": "Side Banner Ad - Used Cars" },
  { "value": "car-worshops-side-banner-ad", "label": "Side Banner Ad - Workshop" },
  { "value": "create-ad-side-banner-ad", "label": "Side Banner Ad - Create Ad" },
  { "value": "create-new-car-ad-side-banner-ad", "label": "Side Banner Ad - Create New Car Ad" },
  { "value": "create-rental-ad-side-banner-ad", "label": "Side Banner Ad - Create Rental Ad" },
  { "value": "create-bidding-ad-side-banner-ad", "label": "Side Banner Ad - Create Bidding Ad" },
  { "value": "Strip-Banner", "label": "Strip Banner" },
  { "value": "Free-Valuation-Strip-Banner-1", "label": "Strip Banner - Free Valuation" },
  { "value": "Plate-Numbers-Strip-Banner", "label": "Strip Banner - Plate Numbers" },
  { "value": "Product-View-Strip-Banner-1", "label": "Strip Banner - Product View" },
  { "value": "products-bank-ad", "label": "Strip Banner Ad - Calculator" },
  { "value": "rental-strip-banner-ad", "label": "Strip Banner Ad - Rental" },
  { "value": "Strip-Banner-I-Max", "label": "Strip Banner I-Max" },
  { "value": "Vertical-Side-Banner", "label": "Vertical Side Banner" },
  { "value": "Auth-Left-Side", "label": "Side Banner Left (Vertical - Login & Register Page) " },
  { "value": "Auth-Right-Side", "label": "Side Banner Right (Vertical - Login & Register Page) " },
  { "value": "products-electric-side-banner-ad", "label": "Side Banner Ad - Electric" },
  { "value": "accessories-side-banner-ad", "label": "Side Banner Ad - Accessories" },
  { "value": "dealer-side-banner-ad", "label": "Side Banner Ad - Dealer" },
  // { "value": "products-dealer-side-banner-ad", "label": "Side Banner Ad - Dealer" },
//   { "value": "mobile-top-header-banner-ad", "label": "Mobile Top Header Banner Ad" },
];

// export const biddingBannerTypes = [
//   { value: "Desktop-Product-Banner", label: "Desktop Product Banner" },
//   { value: "Mobile-Ad", label: "Mobile Banner" },
//   { value: "I-Max", label: "I-Max" },
//   { value: "Side-Banner", label: "Side Banner (Square)" },
//   { value: "Strip-Banner", label: "Strip Banner" },
//   { value: "Product-View-Side-Banner-Ad", label: "Side Banner Ad - Car Details" },
//   { value: "Product-View-Center-Ad", label: "Center Ad - Product View"  },
//   { value: "Product-View-Strip-Banner-1", label: "Strip Banner - Product View" },
//   { value: "products-bidding-side-banner-ad", label: "Side Banner Ad - Bidding Cars" },
//   { value: "products-dealer-side-banner-ad", label: "Side Banner Ad - Dealer" },
//   { value: "products-commercial-side-banner-ad", label: "Side Banner Ad - Commercial" },
//   { value: "Vertical-Side-Banner", label: "Vertical Side Banner" },
//   { value: "Strip-Banner-I-Max", label: "Strip Banner I-Max" },
// ];

export const pageTypes = [
  { value: null, label: "All pages" },
  { value: "Landing-Page", label: "Landing Page" },
  { value: "Product-Page", label: "Product Detail Page" },
  { value: "Used-Car-Page", label: "Used Car Page" },
  { value: "New-Car-Page", label: "New Car Page" },
  { value: "Rental-Car-Page", label: "Rental Car Page" },
  { value: "Commercial-Car-Page", label: "Commercial Car Page" },
  { value: "Car-Plate-Page", label: "Car Plate Number Page" },
  { value: "Loan-Calculator-Page", label: "Loan Calculator Page" },
  { value: "Dealer-Company-Page", label: "Dealer Company Page" },
  { value: "Insurance-Page", label: "Insurance Page" },
  { value: "Free-Valuation-Page", label: "Free Valuation Page" },
  { value: "Careers-Page", label: "Careers Page" },
  { value: "Article-Listing-Page", label: "Article Listing Page" },
  { value: "Article-Detail-Page", label: "Article Detail Page" },
  { value: "Car-Workshop-Page", label: "Car Workshop Page" },
  { value: "Other-Page", label: "Other Page" },
];

export const rentalTypes = [
  { value: "all", label: "All" },
  { value: "short_term", label: "Short Term" },
  { value: "long_term", label: "Long Term" },
  { value: "private_hire", label: "Private Hire" },
  { value: "wedding_car", label: "Wedding Car" },
];

export const ownerIdTypes = [
  { value: "business", label: "Business (e.g. 51234567M)" },
  {
    value: "organization",
    label: "Club/Association/Organisation (e.g. T08PQ1234A)",
  },
  { value: "company", label: "Company (e.g. 198912345K)" },
  { value: "foreign_company", label: "Foreign Company (e.g. T08FC1234A)" },
  {
    value: "foreign_id",
    label: "Foreign Identification Number (e.g. F/G1234567N)",
  },
  { value: "government", label: "Government (e.g. T08GA1234A)" },
  {
    value: "limited_liability",
    label: "Limited Liability Partnership (e.g. T08LL1234A)",
  },
  {
    value: "limited_partnership",
    label: "Limited Partnership (e.g. T08LP1234A)",
  },
  { value: "professional", label: "Professional (e.g. T08PQ1234A)" },
  { value: "sg_nric", label: "Singapore NRIC (e.g. S1234567D)" },
  { value: "statutory", label: "Statutory Board (e.g. T08GB1234A)" },
];

export const adsLocations = [
  { value: "Web", label: "Web" },
  { value: "Mobile", label: "Mobile" },
];

export const category = [
  'Authorised Dealer',
  'Parallel Dealer',
  'Cat A COE',
  'Cat B COE',
  'Any',
];

export const newCarConditions = [
  { value: "Brand New", label: "Brand New" },
  { value: "Almost New", label: "Almost New" },
]