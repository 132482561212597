import React, { useState, useEffect, useContext } from "react";
import { Table, Input, Spin } from "antd";
import "./dealer.scss";
import { FiSearch } from "react-icons/fi";
import {  RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";
import { BiTransfer } from "react-icons/bi";
import moment from "moment";
import { FaUserTag } from "react-icons/fa";
import CustomDeleteModal from "../custom_components/customDeleteModal";
import { IconButton, PrimaryButton } from "../custom_components/customButton";
import { MdAddCircle, MdDesktopAccessDisabled, MdOutlineDesktopWindows } from "react-icons/md";
import DealerForm from "../forms/dealers/DealerForm";
import DirectToDealerForm from "../forms/dealers/DirectToDealerForm";
import { colorScheme } from "../contants/variables";
import { deleteUser, fetchUsers, updateAccountStatus } from "../stores/api_calls/users";
import { AccessContext } from "../stores/contexts/accessContext";
import { ChipsWithTooltips } from "../custom_components/chips";
import { error, success } from "../contants/snackbars";
import ClientForm from "../forms/dealers/ClientForm";
import { useLocation } from "react-router-dom";

const DealerLists = ({ isDealer }) => {
  const location = useLocation();
  const [roles, permissions] = useContext(AccessContext);
  const [tableData, setTableData] = useState([]);
  const [isLoading, setIsLoading] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [keywordSearch, setKeywordSearch] = useState("");
  const [showModal, setShowModal] = useState({
    showDelete: false,
    showClient: false,
    showForm: false,
    changeDealer: false,
    data: {},
  });
  const [clients, setClients] = useState([]);
  const [tablePagination, setTablePagination] = useState({
    current: 1,
    pageSize: 10,
    total: 26,
    defaultPageSize: 10,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "30"],
  });

  const columns = [
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      // sorter: true,
      render: (text, record) =>
        moment.utc(record.date_created).local().format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Full Name",
      dataIndex: "userName",
      // sorter: true,
      render: (text, record) =>
        `${record.user_first_name} ${record.user_last_name}`,
      // width: '20%',
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text, record) => {
        if(record.user_contact_emails && record.user_contact_emails.length > 0) {
          return `${record.user_contact_emails
            ?.filter((el) => el.is_active === true)
            .map((el) => el.email)}`
        }
        return '-';
      }
      // width: '20%',
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      render: (text, record) =>
        `${record.user_contact_numbers
          ?.filter((el) => el.is_active === true && el.number)
          .map((el) => el.number)}`,
    },
    {
      title: "Dealer Company",
      dataIndex: "userClient",
      render: (text, record) => `${record.user_client ?? "NOT APPLICABLE"}`,
    },
    {
      title: "Total Product",
      dataIndex: "totalProduct",
      render: (text, record) => (
        <div className="total-product"> {record.product_plate_numbers.length} </div>
      ),
    },
    {
      title: "Permissions",
      dataIndex: "permissions",
      render: (text, record) => (
        <ChipsWithTooltips dataArray={record.user_permissions} />
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      width: "13%",
      render: (text, record) => (
        <div className="actions-icons-container">
          <IconButton
            className="icon-button edit"
            tootTipTitle={isDealer ? "Edit Dealer" : "Edit Direct Seller"}
            Icon={<RiEdit2Fill />}
            onClick={() => onOpenForm("edit", record)}
          />

          {!isDealer && (
            <IconButton 
              className="icon-button change"
              tootTipTitle={"Convert To Dealer"}
              Icon={<BiTransfer />}
              onClick={() => onOpenDealerForm(record)}
            />
          )}
          
          <IconButton
            className="icon-button delete"
            tootTipTitle={isDealer ? "Delete Dealer" : "Delete Direct Seller"}
            Icon={<RiDeleteBin2Fill />}
            onClick={() => onDelete(record)}
          />
          <IconButton
            className={"icon-button delete"}
            tootTipTitle={
              record.is_active ? "Deactivate Account" : "Activate Account"
            }
            Icon={
              record.is_active ? (
                <MdOutlineDesktopWindows color="green" />
              ) : (
                <MdDesktopAccessDisabled />
              )
            }
            onClick={() => onUpdateAccountStatus(record._id)}
          />
        </div>
      ),
    },
  ];

  useEffect(() => {
    refetchLists();
  }, [location]);

  useEffect(() => {
    if (tablePagination.current || tablePagination.pageSize) {
      refetchLists();
    }
  }, [tablePagination.current, tablePagination.pageSize]);

  const refetchLists = () => {
    const pagination = {
      page: tablePagination.current,
      limit: tablePagination.pageSize,
      keyword: keywordSearch,
      roleIds: isDealer
        ? ["619e5d67e7040000a2007b09"]
        : ["619e5d55e7040000a2007b08"],
      directSeller: isDealer ? false : true,
    };

    fetchData(pagination);
  };

  const fetchData = (data) => {
    setIsLoading(true);
    if (data) {
      const users = fetchUsers(data);
      users
        .then((res) => {
          if (res.data.success) {
            setTableData(res.data.data);
            setIsLoading(false);
            setTablePagination({
              ...tablePagination,
              total: res.data.meta.pagination.total,
            });
          }
        })
        .catch((e) => {
          console.log(e);
          setIsLoading(false);
        });
    }
  };

  const onEnter = (e) => {
    if (e.key === "Enter") {

      const pagination = {
        page: 1,
        limit: 10,
        keyword: keywordSearch,
        roleIds: isDealer
          ? ["619e5d67e7040000a2007b09"]
          : ["619e5d55e7040000a2007b08"],
      };

      fetchData(pagination);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTablePagination(pagination);
  };

  const onDelete = (record) => {
    setShowModal({ ...showModal, showDelete: true, data: record });
  };

  const onDeleteSubmit = () => {
    const removeUser = deleteUser(showModal.data._id);

    removeUser
      .then((res) => {
        if (res.data.success) {
          success("User Deleted Successfully");
          setShowModal({ ...showModal, showDelete: false, data: {} });
          refetchLists();
        }
      })
      .catch((e) => {
        error(
          `Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`
        );
      });
  };

  const onOpenForm = (action, record) => {
    if (action === "add") {
      setIsEdit(false);
      setShowModal({ ...showModal, showForm: true });
    } else {
      setIsEdit(true);
      setShowModal({ ...showModal, showForm: true, data: record });
    }
  };
  
  const onOpenDealerForm = (record) => {
    setIsEdit(true);
    setShowModal({ ...showModal, showForm: true, changeDealer: true, data: record });
  };

  const onSubmitForm = () => {
    setShowModal({ ...showModal, showForm: false, data: {} });
  };

  const onUpdateAccountStatus = async (id) => {
    updateAccountStatus(id).then(res => {
      if (res.data.success) {
        success("Account status has been updated successfully");
        refetchLists();
      }
    })
  }

  return (
    <div className="dealer-table-container">
      <div className="table-title-icon primary">
        <FaUserTag />
      </div>
      <p className="table-title-text primary">
        {isDealer ? "Dealers List" : "Direct Sellers List"}
      </p>

      <div className="search-container">
        <div className="add-button-container">
          {isDealer && (
            <PrimaryButton
              Icon={<MdAddCircle />}
              color={colorScheme.primary}
              label="Manage Dealer Company"
              onClick={() => setShowModal({ ...showModal, showClient: true })}
            />
          )}
        </div>

        <div className="add-button-container">
          <PrimaryButton
            Icon={<MdAddCircle />}
            color={colorScheme.primary}
            label={isDealer ? "Add New Dealer" : "Add Direct Seller"}
            onClick={() => onOpenForm("add")}
          />
        </div>

        <Input
          style={{ width: 300 }}
          size="large"
          placeholder="Search Record"
          prefix={<FiSearch />}
          onChange={(e) => setKeywordSearch(e.target.value)}
          onKeyPress={onEnter}
        />
      </div>

      <div className='scrollable-table-container'>
        <Table
          columns={columns}
          rowKey={(record) => record._id}
          dataSource={tableData}
          pagination={tablePagination}
          loading={{ indicator: <Spin size="large" />, spinning: isLoading }}
          onChange={handleTableChange}
          bordered
          sticky
        />
      </div>

      <ClientForm
        visible={showModal.showClient}
        onCancel={() =>
          setShowModal({ ...showModal, showClient: false, data: {} })
        }
        clients={clients}
        setClients={setClients}
      />

      {
        showModal.showForm &&
        <DealerForm
          isEdit={isEdit}
          visible={showModal.showForm}
          roles={roles}
          permissions={permissions}
          refetchLists={refetchLists}
          onSubmit={onSubmitForm}
          onCancel={() => {
            setShowModal({ ...showModal, showForm: false, changeDealer: false, data: {} });
            setIsEdit(false);
          }}
          clients={clients}
          data={showModal.data}
          isDealer={isDealer}
        />
      }
      
      {
        showModal.showForm && showModal.changeDealer &&
        <DirectToDealerForm
          isEdit={isEdit}
          visible={showModal.showForm}
          roles={roles}
          permissions={permissions}
          refetchLists={refetchLists}
          onSubmit={onSubmitForm}
          onCancel={() => {
            setShowModal({ ...showModal, showForm: false, changeDealer: false, data: {} });
            setIsEdit(false);
          }}
          clients={clients}
          data={showModal.data}
          isDealer={true}
        />
      }

      <CustomDeleteModal
        title="Delete Dealer"
        visible={showModal.showDelete}
        onSubmit={onDeleteSubmit}
        onCancel={() =>
          setShowModal({ ...showModal, showDelete: false, data: {} })
        }
        data={{
          itemName: `${showModal.data.user_first_name} ${showModal.data.user_last_name}`,
          type: "dealer / advertiser",
        }}
      />
    </div>
  );
};

export default DealerLists;
