import { Col, Row } from 'antd'
import React , {useEffect , useState} from 'react'
import { IconButton } from '../custom_components/customButton'
import { deleteModel, fetchModels } from '../stores/api_calls/carlists';
import {RiDeleteBin2Fill , RiEdit2Fill} from 'react-icons/ri'
import './brands.scss'
import {ModelEditForm} from '../forms/brands/ModelForm';
import CustomDeleteModal from '../custom_components/customDeleteModal';
import { error, success } from '../contants/snackbars';

const Models = (props) => {

    const {itemId ,models , getModels} = props
    // const [models , setModels] = useState([])
    // const [fetchLoading , setFetchLoading] = useState(false)
    const [showModal , setShowModal] = useState({showEditForm:false, showDelete :false , data:{}})

    useEffect(()=>{
        if(itemId){
            getModels()
        }
    },[itemId])

    // const getModels = () => {
    //     setFetchLoading(true)
    //     const models = fetchModels(itemId)
    //     models.then((res)=>{
    //         if(res.data.success){
    //             setFetchLoading(false)
    //             setModels(res.data.data)
    //         }
    //     }).catch((e)=>{
    //         console.log(e)
    //         setFetchLoading(false)
    //     })
    // }

    const handleEditModel = (record) => {
        setShowModal({...showModal , showEditForm:true ,  data:record})
    }

    const handleDeleteModel = (record) => {
        setShowModal({...showModal , showDelete:true ,  data:record})
    }

    const onDeleteModelSubmit = () => {
        const removeModel = deleteModel(showModal.data._id)
        removeModel.then((res)=>{
            if(res.data.success){
                getModels()
                success('Model Deleted Successfully')
                setShowModal({...showModal , showDelete:false , data:{}})
            }
        }).catch((e)=>{
            error(`Opps! ${e.response.data.server_response} . Fetched Failed. Please try again later`)
        })
    }

    return (
        <div className='models-container'>
            {models.isLoading?
                <p>Fetching Lists...</p>
            :!models.isLoading && models.data.length?
                <div>
                    <Row gutter={[15,15]}>
                        {models.data.map((model , index)=>
                            <Col key={index} span={models.length > 3 ? 6 : 8}>
                                <div className='model-container'>
                                    <p>{model.name}</p>
                                    <div className='actions-icons-container'>
                                        <IconButton className='icon-button edit' tootTipTitle="Edit Model" Icon={<RiEdit2Fill/>} onClick={()=>handleEditModel(model)}/>
                                        <IconButton className='icon-button delete' tootTipTitle="Delete Model" Icon={<RiDeleteBin2Fill/>} onClick={()=>handleDeleteModel(model)}/>
                                    </div>
                                </div>
                            </Col>
                        )}
                    </Row>
                </div>
            :<p>No Models Found</p>
            }

            <ModelEditForm
                getModels={getModels}
                visible={showModal.showEditForm}
                onCancel={()=>{
                    setShowModal({...showModal , showEditForm:false , data:{}})
                }}
                data={showModal.data}
            />

            <CustomDeleteModal
                title={`Delete ${showModal.type}`}
                visible={showModal.showDelete}
                onSubmit={onDeleteModelSubmit}
                onCancel={()=>setShowModal({...showModal , showModelDelete:false , data:{}})}
                data={{itemName:`${showModal.data.name}` , type:showModal.type}}
            />
        </div>
    )
}

export default Models
